import { Link } from 'gatsby';
import React from 'react';

import { ImagesBrands } from '../../Images';

const Hero: React.FC = () => {
    return (
        <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 flex flex-col flex-wrap justify-center items-center lg:px-6 lg:flex-row ">
            <div className="w-full lg:w-1/2">
                <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                    Profesjonalne oklejanie samochodów.
                </h1>
                <p className="mb-6 text-sm lg:text-base">
                    Sprawdź i skorzystaj z oferty firmy WrapTown. U nas możesz
                    zmienić kolor swojego pojazdu na jaki tylko chcesz.
                    Dodatkowo zaopiekujemy się nowym samochodem przyklejając
                    folię ochronną PPF, a wszystko doprawimy dechromingiem.
                </p>
                <Link to="/uslugi">
                    <button className="h-10 px-3 md:h-12 mr-4 md:px-5 text-white bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                        Dowiedz się więcej
                    </button>
                </Link>
                <Link to="/kontakt">
                    <button className="h-10 px-3 md:h-12 md:px-5 text-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline hover:bg-blue-700 hover:text-white">
                        Kontakt
                    </button>
                </Link>
            </div>
            <div className="flex justify-center items-center w-full lg:w-1/2 lg:justify-end mx-auto max-w-md px-4 mt-10 sm:max-w-2xl sm:px-6 lg:mt-0 lg:max-w-none lg:px-0">
                <img
                    className="w-11/12"
                    src="../../site/car.svg"
                    alt="Zmiana koloru auta Kościan, Poznań, Leszno - sprawdź wraptown.pl"
                />
            </div>
            <div className="grid grid-flow-row gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-5 ">
                {ImagesBrands.map((image) => {
                    return (
                        <img
                            className="transition-opacity opacity-30 hover:opacity-100"
                            src={image.href}
                            alt={image.alt}
                            key={image.id}
                        />
                    );
                })}
            </div>
        </main>
    );
};

export default Hero;
