interface Project {
    id: number;
    href: string;
    alt: string;
    cat: string;
    link: string;
}

export const ProjectsImgs: Project[] = [
    {
        id: 0,
        href: '../../projects/audi-a6kombi/cover.webp',
        alt: 'Zmiana koloru auta - Audi A6 Kombi - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/audi-a6-kombi',
    },
    {
        id: 1,
        href: '../../projects/maseratti/cover.webp',
        alt: 'Zmiana koloru auta - Maseratti Ghibli - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/maseratti-ghibli',
    },
    {
        id: 2,
        href: '../../projects/vw-touran/cover.webp',
        alt: 'Zmiana koloru auta - Volkswagen Touran - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/volkswagen-touran',
    },
    {
        id: 3,
        href: '../../projects/audi-a6/cover.webp',
        alt: 'Zmiana koloru auta - Audi A6  - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/audi-a6-blue',
    },
    {
        id: 4,
        href: '../../projects/audi-a6-2/cover.webp',
        alt: 'Zmiana koloru auta - Audi A6  - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/audi-a6-gray',
    },
    {
        id: 5,
        href: '../../projects/audi-a5/cover.webp',
        alt: 'Dechroming - Audi A5 Quattro - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/audi-a5-quattro',
    },
    {
        id: 6,
        href: '../../projects/hyundai/1.webp',
        alt: 'Ochrona lakieru - Hyundai Tucson  - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/hyundai-tucson',
    },
    {
        id: 7,
        href: '../../projects/audi-a5-kombi/1.webp',
        alt: 'Dechroming - Audi A6 Kombi - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/audi-a6-kombi-2',
    },
    {
        id: 8,
        href: '../../projects/opel-vivaro/1.webp',
        alt: 'Zmiana koloru auta - Opel Vivaro - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/opel-vivaro',
    },
    {
        id: 9,
        href: '../../projects/skoda-kamiq/1.webp',
        alt: 'Ochrona lakieru - Skoda Kamiq - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/skoda-kamiq',
    },
    {
        id: 10,
        href: '../../projects/mercedes-a/1.webp',
        alt: 'Zmiana koloru auta - Mercedes AMG A35 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/mercedes-amg-a35',
    },
    {
        id: 11,
        href: '../../projects/bmw-750i/1.webp',
        alt: 'Ochrona lakieru - BMW 750i - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-750i',
    },
    {
        id: 12,
        href: '../../projects/bmwx6/1.webp',
        alt: 'Ochrona lakieru - BMW X6 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-x6',
    },
    {
        id: 13,
        href: '../../projects/bmw-explozja/1.webp',
        alt: 'Zmiana koloru auta - BMW 330D E92 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/bmw-330d-e92',
    },
    {
        id: 14,
        href: '../../projects/volvo-v60/1.webp',
        alt: 'Dechroming - Volvo V60 - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/volvo-v60',
    },
    {
        id: 15,
        href: '../../projects/audi-a6-3/1.webp',
        alt: 'Dechroming - Audi A6 - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/audi-a6-kombi-3',
    },
    {
        id: 15,
        href: '../../projects/volvo-v60-2/1.webp',
        alt: 'Ochrona lakieru - Volvo V60 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/volvo-v60-2',
    },
    {
        id: 16,
        href: '../../projects/audi-q5/1.webp',
        alt: 'Zmiana koloru auta - Audi Q5 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/audi-q5',
    },
    {
        id: 16,
        href: '../../projects/mercedes-gl50/1.webp',
        alt: 'Zmiana koloru auta - Mercedes GL - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/mercedes-gl',
    },
    {
        id: 17,
        href: '../../projects/vw-tiguan/1.webp',
        alt: 'Dechroming - Volkswagen Tiguan - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/volkswagen-tiguan',
    },
    {
        id: 18,
        href: '../../projects/bmw-m235i/1.webp',
        alt: 'Ochrona lakieru - BMW M235i - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-m235i',
    },
    {
        id: 19,
        href: '../../projects/bmw-340i/1.webp',
        alt: 'Dechroming - BMW 340i - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/bmw-340i',
    },
    {
        id: 20,
        href: '../../projects/vw-tcross/1.webp',
        alt: 'Ochrona lakieru - Volkswagen T-Cross - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/volkswagen-tcross',
    },
    {
        id: 21,
        href: '../../projects/jeep-grand-cherokee/1.webp',
        alt: 'Dechroming - Jeep Grand Cherokee - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/jeep-grand-cherokee',
    },
    {
        id: 22,
        href: '../../projects/isuzu-dmax/1.webp',
        alt: 'Dechroming - Isuzu D-Max - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/isuzu-d-max',
    },
    {
        id: 23,
        href: '../../projects/volvo-xc90/1.webp',
        alt: 'Ochrona lakieru - Volvo XC90 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/volvo-xc90',
    },
    {
        id: 24,
        href: '../../projects/renault-trafic/1.webp',
        alt: 'Ochrona lakieru - Renault Trafic - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/renault-trafic',
    },
    {
        id: 24,
        href: '../../projects/vw-crafter/1.webp',
        alt: 'Ochrona lakieru - Volkswagen Crafter - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/volkswagen-crafter',
    },
    {
        id: 25,
        href: '../../projects/range-rover-evoque/1.webp',
        alt: 'Ochrona lakieru - Range Rover Evoque - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/range-rover-evoque',
    },
    {
        id: 26,
        href: '../../projects/volvo-s90/1.webp',
        alt: 'Dechroming - Volvo S90 - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/volvo-s90',
    },
    {
        id: 26,
        href: '../../projects/porsche-911/1.webp',
        alt: 'Ochrona lakieru - Porsche 911 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/porsche-911',
    },
    {
        id: 27,
        href: '../../projects/audi-a6-4/1.webp',
        alt: 'Dechroming - Audi A6 - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/audi-a6-kombi-4',
    },
    {
        id: 27,
        href: '../../projects/bmw-m2/3.webp',
        alt: 'Ochrona lakieru - BMW M2 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-m2',
    },
    {
        id: 28,
        href: '../../projects/mercedes-v300d/1.webp',
        alt: 'Ochrona lakieru - Mercedes V 300d - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/mercedes-v300d',
    },
    {
        id: 29,
        href: '../../projects/mercedes-s-w221/1.webp',
        alt: 'Zmiana koloru auta - Mercedes S W221 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/mercedes-s-w221',
    },
    {
        id: 30,
        href: '../../projects/toyota-yaris-cross/1.webp',
        alt: 'Ochrona lakieru - Toyota Yaris Cross - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/toyota-yaris-cross',
    },
    {
        id: 31,
        href: '../../projects/toyota-corolla-ts/1.webp',
        alt: 'Ochrona lakieru - Toyota Corolla TS - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/toyota-corolla-ts',
    },
    {
        id: 32,
        href: '../../projects/bmw-x5m/1.webp',
        alt: 'Ochrona lakieru - BMW X5 M - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-x5m',
    },
    {
        id: 33,
        href: '../../projects/bmw-z3/1.webp',
        alt: 'Zmiana koloru auta - BMW Z3 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/bmw-z3',
    },
    {
        id: 34,
        href: '../../projects/mercedes-gt43/1.webp',
        alt: 'Ochrona lakieru - Mercedes GT 43 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/mercedes-gt43',
    },
    {
        id: 35,
        href: '../../projects/audi-q8/1.webp',
        alt: 'Dechroming - Audi Q8 - wraptown.pl',
        cat: 'Dechroming',
        link: '/realizacje/audi-q8',
    },
    {
        id: 36,
        href: '../../projects/volkswagen-passat/1.webp',
        alt: 'Zmiana koloru auta - Volkswagen Passat - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/volkswagen-passat',
    },
    {
        id: 37,
        href: '../../projects/toyota-corolla-cross/1.webp',
        alt: 'Ochrona lakieru - Toyota Corolla Cross - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/toyota-corolla-cross',
    },
    {
        id: 38,
        href: '../../projects/skoda-octavia/1.webp',
        alt: 'Ochrona lakieru - Skoda Octavia - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/skoda-octavia',
    },
    {
        id: 39,
        href: '../../projects/mercedes-gle-450d/1.jpg',
        alt: 'Zmiana koloru auta - Mercedes GLE 450d - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/mercedes-gle-450d',
    },
    {
        id: 40,
        href: '../../projects/bmw-5/1.jpg',
        alt: 'Ochrona lakieru PPF - BMW serii 5 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-5',
    },
    {
        id: 41,
        href: '../../projects/bmw-x5-xdrive50e/1.jpg',
        alt: 'Ochrona lakieru PPF - BMW X5 xDrive50e - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-x5-xdrive50e',
    },
    {
        id: 42,
        href: '../../projects/mercedes-glc-220d/1.jpg',
        alt: 'Ochrona lakieru PPF - Mercedes GLC 220d - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/mercedes-glc-220d',
    },
    {
        id: 43,
        href: '../../projects/audi-a3/1.jpg',
        alt: 'Zmiana koloru auta - Audi A3 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/audi-a3',
    },
    {
        id: 44,
        href: '../../projects/bmw-x7/1.jpg',
        alt: 'Ochrona lakieru - BMW X7 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-x7',
    },
    {
        id: 45,
        href: '../../projects/bmw-m8/1.jpg',
        alt: 'Ochrona lakieru - BMW M8 - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/bmw-m8',
    },
    {
        id: 46,
        href: '../../projects/mercedes-eqe-suv/1.jpg',
        alt: 'Ochrona lakieru - Mercedes EQE SUV - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/mercedes-eqe-suv',
    },
    {
        id: 47,
        href: '../../projects/bmw-m5/1.jpg',
        alt: 'Zmiana koloru auta - BMW M5 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/bmw-m5',
    },
    {
        id: 47,
        href: '../../projects/bmw-m5-2/1.jpg',
        alt: 'Zmiana koloru auta - BMW M5 - wraptown.pl',
        cat: 'Zmiana koloru auta',
        link: '/realizacje/bmw-m5-2',
    },
    {
        id: 48,
        href: '../../projects/skoda-superb/1.jpg',
        alt: 'Ochrona lakieru - Skoda Superb - wraptown.pl',
        cat: 'Ochrona lakieru',
        link: '/realizacje/skoda-superb',
    },
];
