import { Link } from 'gatsby';
import React from 'react';

const Contact: React.FC = () => {
    return (
        <main className="px-4 bg-gray-100 flex justify-center pt-10 pb-10 lg:px-6">
            <div className="flex flex-col items-center xl:inset-0 xl:h-full xl:w-10/12">
                <div className="lg:text-center">
                    <h2 className="w-full text-3xl mb-6 font-bold text-black sm:text-3xl sm:leading-tight">
                        Zapraszamy do skorzystania z naszych usług oklejania
                        samochodów
                    </h2>
                    <p className="mb-6 lg:mb-12">
                        Możesz do nas napisać, zadzwonić lub zajrzeć na nasze
                        social media 😊
                    </p>
                    <Link to="/kontakt">
                        <button className="h-12 mr-4 px-5 text-white bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                            Skontaktuj się z nami
                        </button>
                    </Link>
                </div>
            </div>
        </main>
    );
};

export default Contact;
