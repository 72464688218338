import React from 'react';
import About from '../components/home/about';
import Contact from '../components/home/contact';
import Hero from '../components/home/hero';
import Projects from '../components/home/projects';
import Layout from '../components/layout/layout';

const IndexPage: React.FC = () => {
    return (
        <Layout
            title="WrapTown - Profesjonalne oklejanie samochodów"
            desc="Profesjonalne oklejanie samochodów, usługi takie jak zmiana koloru auta, dechroming czy ochrona lakieru folią PPF w firmie WrapTown. Sprawdź sam naszą ofertę oklejania aut."
            url="https://wraptown.pl"
            type="website"
        >
            <Hero />
            <About />
            <Projects />
            <Contact />
        </Layout>
    );
};

export default IndexPage;
