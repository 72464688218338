import { Link } from 'gatsby';
import React from 'react';

const About: React.FC = () => {
    return (
        <main className="px-4 max-w-7xl mx-auto pt-6 pb-6 lg:pt-20 lg:pb-20 flex items-center lg:px-6">
            <div className="hidden lg:block lg:w-1/2">
                <img
                    className="w-4/5"
                    src="../../site/mechanic.webp"
                    alt="WrapTown - zmiana koloru auta, dechroming, ochrona lakieru"
                />
            </div>
            <div className="w-full lg:w-1/2">
                <h2 className="text-4xl mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                    Poznaj WrapTown
                </h2>
                <p className="w-full lg:w-4/5 mb-10">
                    WrapTown to firma specjalizująca się w oklejaniu pojazdów.
                    Zmieniamy kolor auta, zajmujemy się detalami oraz
                    przyciemniamy lampy. Dodatkowo oklejamy nowe samochody folią
                    ochronną aby chroniła nowy lakier przed otarciami czy
                    rysami.
                </p>
                <div className="w-4/5">
                    <section className="mb-8">
                        <h3 className="text-2xl mb-2 font-bold text-black">
                            Zmiana koloru auta
                        </h3>
                        <p className="w-full lg:w-3/5 mb-2">
                            Odmień swój samochód oklejając go folią bez
                            ingerencji w lakier samochodu.
                        </p>
                        <Link to="/uslugi/zmiana-koloru-auta">
                            <button className="font-semibold text-blue-700">
                                Sprawdź ofertę
                            </button>
                        </Link>
                    </section>
                    <section className="mb-8">
                        <h3 className="text-2xl mb-2 font-bold text-black">
                            Dechroming
                        </h3>
                        <p className="w-full lg:w-3/5 mb-2">
                            Dodaj samochodowi świeżego wyglądu poprzez oklejenie
                            listw czy grilla folią z kolorem.
                        </p>
                        <Link to="/uslugi/dechroming">
                            <button className="font-semibold text-blue-700">
                                Sprawdź ofertę
                            </button>
                        </Link>
                    </section>
                    <section>
                        <h3 className="text-2xl mb-2 font-bold text-black">
                            Ochrona lakieru
                        </h3>
                        <p className="w-full lg:w-3/5 mb-2">
                            Zabezpiecz lakier w swoim aucie bezbarwną folią PPF
                            co pozwoli ochronić nowy lakier przed uszkodzeniami
                            i rysami.
                        </p>
                        <Link to="/uslugi/ochrona-lakieru">
                            <button className="font-semibold text-blue-700">
                                Sprawdź ofertę
                            </button>
                        </Link>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default About;
