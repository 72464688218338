import { Link } from 'gatsby';
import React from 'react';
import { ProjectsImgs } from '../../Projects';

const Projects: React.FC = () => {
    return (
        <main className="px-4 max-w-7xl mx-auto pt-10 pb-6 lg:px-6 lg:pt-20 lg:pb-20 ">
            <div className="flex flex-col lg:flex-row justify-between items-center mb-10 lg:mb-14">
                <h2 className="text-3xl font-bold text-black w-full mb-6 lg:mb-0 sm:text-4xl sm:leading-tight">
                    Nasze ostatnie realizacje
                </h2>
            </div>
            <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-2 lg:gap-8">
                {ProjectsImgs.reverse()
                    .filter((img, index) => index < 6)
                    .map((project) => {
                        return (
                            <Link key={project.id} to={project.link}>
                                <div className="relative">
                                    <div className="absolute bottom-4 left-4 h-8 px-3 md:h-10 mr-4 md:px-5 text-white flex justify-center items-center bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                                        <h2 className="font-bold">
                                            {project.cat}
                                        </h2>
                                    </div>
                                    <img src={project.href} alt={project.alt} />
                                </div>
                            </Link>
                        );
                    })}
            </div>
        </main>
    );
};

export default Projects;
